import {addCheckedSubset, removeCheckedSubset} from '~/composables/useHelper';

let selectValues = ref([])
let selectedIndex = null;

let perPageValues = ref([10,25,50,100,250])
let totalResults = ref(0)
let totalPageRef = ref(0)
let currentPage = ref(1)
let fromPage = ref(0)
let toPage = ref(0)

let isLoaded = ref(false)
let sidebarIsOpen = ref(false)
let currentComponent = ref(null)

let response = ref({errors: null, success: null})

// Computed Properties ...
let totalPage = computed({
    get: () => totalPageRef.value,
    set: (val) => {
        totalPageRef.value = val;
    }
})

let nextPage = computed(() => parseInt(totalPage.value) === parseInt(currentPage.value) ? false : parseInt(currentPage.value) + 1)
let prevPage = computed(() => parseInt(currentPage.value) <= 1 ? false : parseInt(currentPage.value) - 1)

export default (data = [], callback = () => {}) => {
    const {locale} = useI18n()
    const {selectedWarehouse} = useListing()

    const storeConfig = useStoreConfig()
    const {currency, listingConfig} = storeToRefs(storeConfig)

    const search = useState("searchInput")
    const perPage = useState('perPage', () => 8)
    const orderBy = useState('selectedSortOption', () => Object.assign({}))

    let baseParams = useState('baseParams', () => {
        return {
            page: 1,
            limit: perPage.value,
            currency : currency.value,
            warehouseId: selectedWarehouse.value
        }
    })

    const queryStringKey = computed(() => new URLSearchParams({
        ...baseParams.value,
        limit: perPage.value,
        page: currentPage.value,
        sort : orderBy.value?.value ? orderBy.value.value : 0,
        hideOutOfStock: 0,
        warehouseId: selectedWarehouse.value,
        search: search.value,
    }).toString())

    let customData = useState('customData', () => {})
    let items = useState('itemList', () => [])
    let itemsCopy = reactive(items);

    const allAreChecked = computed(() => {
        return (selectValues.value.length === itemsCopy.length)
    })

    const selectAllText = computed(() => {
        return allAreChecked.value ? 'Tümünü Bırak' : 'Tümünü Seç'
    })

    const toggleSelect = (evt, index) => {
        const savedIndex = selectedIndex
        selectedIndex = index

        // If this is the first pass or no shift key was
        // pressed, just bail.
        if (savedIndex === null || !evt.shiftKey) return

        // Shift-clicked the same message :-/
        if (index === savedIndex) return

        // Get the subset of the message list between the
        // two indicies.
        const subset = itemsCopy.slice(
            Math.min(index, savedIndex),
            Math.max(index, savedIndex) + 1
        );


        // Determine the operation based on the checked state
        // of the clicked checkbox.
        const toggleFn = evt.target.checked ? addCheckedSubset : removeCheckedSubset;
        const newSet = subset.map(item => item.id);

        selectValues.value = toggleFn(selectValues.value, newSet);

    }

    const selectAllItems = (event, value = 'id') => {
        const values = itemsCopy.slice(0, itemsCopy.length);
        const newSet = values.map(i => i[value]);
        selectValues.value = allAreChecked.value ? [] : newSet;
    }

    const setNextPage = async () => {
        return new Promise((resolve, reject) => {
            if (nextPage.value) {
                currentPage.value = nextPage.value;
                if (callback && typeof callback === "function") {
                    callback.call()
                }
                resolve();
            }
        });
    }

    const setPrevPage = async () => {
        return new Promise((resolve, reject) => {
            if (prevPage.value) {
                currentPage.value = prevPage.value;
                if (callback && typeof callback === "function") {
                    callback.call()
                }
                resolve();
            }
        });
    }

    return {
        items,
        search,
        selectValues,
        selectedIndex,
        perPage,
        perPageValues,
        totalResults,
        totalPageRef,
        currentPage,
        fromPage,
        toPage,
        orderBy,
        isLoaded,
        sidebarIsOpen,
        currentComponent,
        response,
        toggleSelect,
        selectAllItems,
        nextPage,
        prevPage,
        totalPage,
        setNextPage,
        setPrevPage,
        allAreChecked,
        selectAllText,
        itemsCopy,
        baseParams,
        queryStringKey,
        customData
    }
}
